<template>
  <label
    ref="label"
    class="border rounded p-3 flex justify-between items-center cursor-pointer relative"
    :class="
      isSelected
        ? 'bg-primary bg-opacity-10 border-primary/30'
        : 'bg-white border-divider'
    "
  >
    <div class="flex items-center justify-between w-full text-ellipsis">
      <div class="flex items-center space-x-4 min-w-0">
        <lf-checkbox
          name="selected_stips"
          :value="stip.id"
          v-model="selectionProxy"
          :data-cy="`stip-${stip.id}`"
        ></lf-checkbox>
        <div v-tooltip="stip.name" :title="stip.name" class="truncate">
          {{ stip.name }}
        </div>
      </div>
      <div class="flex items-center space-x-2">
        <div v-if="isSelected" class="flex items-center space-x-1">
          <button
            type="button"
            class="text-primary"
            data-cy="increase-count"
            @click.prevent="decreaseStipCount"
          >
            <icon-base :icon="IconSubtract" view-box="0 -1 20 20" />
          </button>
          <div class="flex justify-center text-headline min-w-4">
            {{ stip.count }}
          </div>
          <button
            type="button"
            class="relative top-0.5 text-primary"
            data-cy="decrease-count"
            @click.prevent="increaseStipCount"
          >
            <icon-base :icon="IconPlus" view-box="20 30 40 20" />
          </button>
        </div>
        <tooltip
          ref="descriptionTooltip"
          placement="left"
          :offset="[0, 0]"
          trigger="click"
          hide-on-click
          sticky="reference"
          :arrow="false"
          theme="none"
          interactive
          data-cy="edit-stip-description-button"
          :on-hidden="resetEditedStip"
        >
          <template #default>
            <icon-base
              :icon="IconNotes"
              class="cursor-pointer"
              :class="{ 'text-primary': stip.description }"
              v-tooltip="stip.description || $t('STIPS.MANAGE_DESCRIPTION')"
              @click.prevent
            />
          </template>
          <template #content>
            <div
              class="p-2 bg-white shadow-lg rounded gap-2 flex flex-col border border-blue-200 min-w-52"
              data-cy="stip-description-container"
              :style="{ width: `${label?.clientWidth}px` }"
            >
              <lf-textarea
                :placeholder="$t('STIPS.ADD_DESCRIPTION')"
                rows="4"
                :name="`stip-description-${stip.id}`"
                noresize
                :character-limit="TEXT_CHARACTER_LIMIT_MEDIUM"
                :value="editedStip.description"
                @key-released="editedStip.description = $event"
                data-cy="stip-description-text-area"
              />
              <modal-buttons
                class="grow justify-end px-[0]"
                :on-cancel="resetEditedStip"
                :on-save="updateStip"
                @click.prevent
              />
            </div>
          </template>
        </tooltip>
      </div>
    </div>
  </label>
</template>
<script setup lang="ts">
import { computed, ref } from "vue";
import LfCheckbox from "@/components/ui/inputs/LfCheckbox.vue";
import IconBase from "@/components/ui/IconBase.vue";
import IconNotes from "@/components/icons/IconNotes.vue";
import IconPlus from "@/components/icons/IconPlus.vue";
import IconSubtract from "@/components/icons/IconSubtract.vue";
import ModalButtons from "@/components/ui/ModalButtons.vue";
import { TEXT_CHARACTER_LIMIT_MEDIUM } from "@/helpers/constants";
import type { IStip } from "@/models/applications";
import type { TippyComponent } from "vue-tippy";

const MIN_STIP_COUNT = 1;
const MAX_STIP_COUNT = 255;

const emit = defineEmits<{
  "update:model-value": [number[]];
  "update:stip": [IStip];
}>();

const props = defineProps<{
  stip: IStip;
  modelValue: number[];
}>();

const label = ref<HTMLLabelElement | null>(null);
const editedStip = ref({ ...props.stip });
const descriptionTooltip = ref<TippyComponent | null>(null);

const isSelected = computed(() => props.modelValue.includes(props.stip.id));

const decreaseStipCount = () => {
  const currentCount = editedStip.value.count;

  if (!currentCount || currentCount <= MIN_STIP_COUNT) {
    return;
  }

  editedStip.value.count = currentCount - 1;
  emit("update:stip", editedStip.value);
};

const increaseStipCount = () => {
  const currentCount = editedStip.value.count;

  if (!currentCount || currentCount >= MAX_STIP_COUNT) {
    return;
  }

  editedStip.value.count = currentCount + 1;
  emit("update:stip", editedStip.value);
};

const selectionProxy = computed({
  get() {
    return props.modelValue;
  },
  set(newVal) {
    emit("update:model-value", newVal);
  }
});

const resetEditedStip = () => {
  if (descriptionTooltip.value?.state.isShown) {
    descriptionTooltip.value.hide();
  }
  editedStip.value = { ...props.stip };
};

const updateStip = () => {
  emit("update:stip", editedStip.value);
  resetEditedStip();
};
</script>
